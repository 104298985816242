import Image from 'next/image';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';

import { SvgIcon, useMediaQuery } from '@gate-academy/react';

import { HeaderWrapper, UserInitials } from './header.styles';

import SideBar from '../mobile-sidebar/mobile-sidebar.component';

import useStore from '~/store';

import GateLogo from '~/public/gate-academy.png';

const Header: FC = () => {
  //This is needed so as to prevent the use of the 'isGreaterThan750Px' value on the server
  const [isComponentMounted, setIsComponentMounted] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [isPageScrolled, setIsPageScrolled] = useState(false);

  const isGreaterThan1000Px = useMediaQuery('(min-width: 62.5em)');

  const currentUser = useStore((state) => state.currentUser);

  useEffect(() => {
    setIsComponentMounted(true);
  }, []);

  const router = useRouter();

  useEffect(() => {
    router.prefetch('/');
  }, [router]);

  useEffect(() => {
    const handlePageScroll = () => {
      if (window.scrollY > 25) setIsPageScrolled(true);
      else setIsPageScrolled(false);
    };

    document.addEventListener('scroll', handlePageScroll);

    return () => {
      document.removeEventListener('scroll', handlePageScroll);
    };
  }, []);

  return (
    <HeaderWrapper isPageScrolled={isPageScrolled}>
      <Image
        placeholder="blur"
        src={GateLogo}
        alt="GATE Academy"
        width={138}
        height={56}
        quality={100}
        onClick={() => router.push('/')}
      />

      {isComponentMounted && isGreaterThan1000Px ? (
        <div>
          <UserInitials>
            {currentUser?.lastName[0]}
            {currentUser?.firstName[0]}
          </UserInitials>
        </div>
      ) : (
        <>
          <SvgIcon
            iconName="menu"
            fillColor="neutral1"
            big
            onClick={() => setIsSideBarOpen(true)}
          />

          <SideBar
            isSideBarOpen={isSideBarOpen}
            closeSideBar={() => setIsSideBarOpen(false)}
          />
        </>
      )}
    </HeaderWrapper>
  );
};

export default Header;
