import { create, StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';

import userSlice, { IUserSlice } from './slices/user.slice';

export type StoreState = IUserSlice;

export type StoreSlice<T> = StateCreator<
  StoreState,
  [['zustand/devtools', never]],
  [],
  T
>;

const useStore = create<StoreState>()(
  devtools((...a) => ({
    ...userSlice(...a),
  }))
);

export default useStore;
