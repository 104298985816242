import { FC, ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';

import PageLoader from '~/components/loading-spinner/page-loader.component';

import useCheckSession from '~/react-query/queries/useCheckSession';

import useStore from '~/store';

import routes from '~/helpers/routes';

interface IProtectedLayoutProps {
  children: ReactNode;
}

const ProtectedLayout: FC<IProtectedLayoutProps> = ({ children }) => {
  const router = useRouter();

  const currentUser = useStore((state) => state.currentUser);

  const { isInitialLoading } = useCheckSession();

  useEffect(() => {
    if (!isInitialLoading && currentUser && !currentUser?.isActive) {
      toast.info(
        'Your account has been deactivated. Please contact your administrator.'
      );

      router.push(routes.login());
    }
  }, [currentUser, isInitialLoading, router]);

  if (isInitialLoading) {
    return <PageLoader />;
  }

  if (!isInitialLoading && !currentUser) {
    window.location.href = `${window.origin}/log-in?redirectTo=${router.asPath}`;

    return <PageLoader />;
  }

  return <>{children}</>;
};

export default ProtectedLayout;
