import { FC } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { ShowView, SvgIcon } from '@gate-academy/react';

import {
  DesktopSidebarNav,
  DesktopSidebarNavListItem,
} from './desktop-sidebar.styles';

import useStore from '~/store';

import useLogout from '~/react-query/mutations/useLogout';

import routes from '~/helpers/routes';

const DesktopSidebar: FC = () => {
  const { pathname } = useRouter();

  const isClient = useStore((state) => state.isClient);
  const isTutor = useStore((state) => state.isTutor);

  const { mutate: logout } = useLogout();

  return (
    <DesktopSidebarNav isClient={isClient}>
      <ul>
        <DesktopSidebarNavListItem isActive={pathname === '/'}>
          <Link href={routes.home()}>
            <SvgIcon iconName="home" />

            <span>Home</span>
          </Link>
        </DesktopSidebarNavListItem>

        {/* <DesktopSidebarNavListItem
          isActive={pathname.startsWith(routes.reports())}
        >
          <Link href={routes.reports()}>
              <SvgIcon iconName="student-report" />

              <span>Progress reports</span>
          </Link>
        </DesktopSidebarNavListItem>

        <DesktopSidebarNavListItem
          isActive={pathname.startsWith(routes.checklists())}
        >
          <Link href={routes.checklists()}>
              <SvgIcon iconName="check-circle-outline" />

              <span>Checklists</span>
          </Link>
        </DesktopSidebarNavListItem> */}

        <DesktopSidebarNavListItem
          isActive={pathname.startsWith(routes.engagements())}
        >
          <Link href={routes.engagements()}>
            <SvgIcon iconName="engagement" />

            <span>Engagements</span>
          </Link>
        </DesktopSidebarNavListItem>

        <DesktopSidebarNavListItem isActive={pathname === routes.schedule()}>
          <Link href={routes.schedule()}>
            <SvgIcon iconName="calendar" />

            <span>Schedule</span>
          </Link>
        </DesktopSidebarNavListItem>

        <DesktopSidebarNavListItem
          isActive={pathname.startsWith(
            isClient ? routes.wards() : routes.tutees()
          )}
        >
          <Link href={isClient ? routes.wards() : routes.tutees()}>
            <SvgIcon iconName="person-outline" />

            <span>{isTutor ? 'Tutees' : 'Wards'}</span>
          </Link>
        </DesktopSidebarNavListItem>

        <ShowView when={isClient}>
          <DesktopSidebarNavListItem
            isActive={pathname.startsWith(routes.tutors())}
          >
            <Link href={routes.tutors()}>
              <SvgIcon iconName="add-person" />

              <span>Tutors</span>
            </Link>
          </DesktopSidebarNavListItem>

          {/* <DesktopSidebarNavListItem isActive={false}>
            <a
              href={process.env.NEXT_PUBLIC_CLIENT_HIRE_TUTOR_URL}
              target="_blank"
              rel="noreferrer noopener"
            >
              <SvgIcon iconName="request-tutor" />

              <span>Request tutor</span>
            </a>
          </DesktopSidebarNavListItem> */}
        </ShowView>

        <ShowView when={isTutor}>
          <DesktopSidebarNavListItem
            isActive={pathname === routes.tutorProfile()}
          >
            <Link href={routes.tutorProfile()}>
              <SvgIcon
                iconName="person"
                style={{
                  height: '2rem',
                  width: '2rem',
                  marginLeft: '-2px',
                }}
              />

              <span>Profile</span>
            </Link>
          </DesktopSidebarNavListItem>
        </ShowView>

        <DesktopSidebarNavListItem isActive={pathname === routes.settings()}>
          <Link href={routes.settings()}>
            <SvgIcon iconName="settings" />

            <span>Settings</span>
          </Link>
        </DesktopSidebarNavListItem>

        <DesktopSidebarNavListItem
          isActive={false}
          logout
          onClick={() => logout()}
        >
          <SvgIcon iconName="power" />
          <p>Log out</p>
        </DesktopSidebarNavListItem>
      </ul>
    </DesktopSidebarNav>
  );
};

export default DesktopSidebar;
