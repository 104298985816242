import axios, {
  AxiosError as AxiosErr,
  AxiosInstance,
  RawAxiosRequestConfig,
} from 'axios';
import qs from 'qs';

import type { GetAllReturn } from '~/types/get-all-return.type';

import replaceUrlParams from '~/utils/replaceUrlParams';

export type AxiosError = AxiosErr<{ message: string }>;

export const apiV1 = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL}/v1`,
  withCredentials: true,
});

export interface IGetAllArgs {
  page: number;
  limit: number;
  filter_by?: Record<string, unknown>;
  q?: string;
  include_fields?: string;
}

export function getAll<T>(axiosInstance: AxiosInstance, url: string) {
  return async ({
    page,
    limit,
    filter_by,
    q,
    include_fields,
  }: IGetAllArgs): Promise<GetAllReturn<T>> => {
    const query: Omit<IGetAllArgs, 'filter_by'> & { filter_by?: string } = {
      limit,
      page,
      q,
      include_fields,
    };

    if (filter_by) {
      let merged = '';
      const filterKeys = Object.keys(filter_by);

      for (let i = 0; i < filterKeys.length; i++) {
        const key = filterKeys[i];

        merged += `${key}:${filter_by[key]}`;

        const isLastKey = i === filterKeys.length - 1;

        if (!isLastKey) {
          merged += '&&';
        }
      }

      query.filter_by = merged;
    }

    const stringifiedQuery = qs.stringify(query);

    const response = await axiosInstance.get(`${url}?${stringifiedQuery}`);

    return response.data.data;
  };
}

const checkUrlLastChar = (url: string) => {
  return (url = url.endsWith('/') ? url : `${url}/`);
};

export function getOne<T>(axiosInstance: AxiosInstance, url: string) {
  url = checkUrlLastChar(url);

  return async (id: string): Promise<T> => {
    const response = await axiosInstance.get(`${url}${id}`);

    return response.data.data;
  };
}

export function addOne<DT = unknown, RT = unknown>(
  axiosInstance: AxiosInstance,
  url: string
) {
  return async (data: DT): Promise<RT> => {
    const response = await axiosInstance.post(url, data);

    return response.data.data;
  };
}

export function updateOne<DT, RT>(axiosInstance: AxiosInstance, url: string) {
  url = checkUrlLastChar(url);

  return async (id: string, data: DT): Promise<RT> => {
    const response = await axiosInstance.patch(`${url}${id}`, data);

    return response.data.data;
  };
}

export function deleteOne<T>(axiosInstance: AxiosInstance, url: string) {
  url = checkUrlLastChar(url);

  return async (id: string): Promise<T> => {
    const response = await axiosInstance.delete(`${url}${id}`);

    return response.data.data;
  };
}

type ParamsMap = Record<string, string>;

const getCompleteUrl = (url: string, paramsMap?: ParamsMap) => {
  const completeUrl = paramsMap ? replaceUrlParams(url, paramsMap) : url;

  return completeUrl;
};

/**
 * Use this function to construct a get request function
 * @param axiosInstance Any Axios instance
 * @param url Any url. Url can contain route params, e.g. /house/:id/guests
 * @returns async function
 */
export function get<TResponse = unknown>(
  axiosInstance: AxiosInstance,
  url: string
) {
  /**
   * @param params Object
   * @param params.paramsMap An object that represents the route params to be replaced, e.g. {id: "moses"} (Optional)
   */
  return async ({
    paramsMap,
    filters = {},
  }: {
    paramsMap?: ParamsMap;
    filters?: Record<string, unknown>;
  } = {}): Promise<TResponse> => {
    let parsedUrl = getCompleteUrl(url, paramsMap);

    if (Object.keys(filters).length) {
      const query = qs.stringify(filters);

      parsedUrl += `?${query}`;
    }

    const response = await axiosInstance.get(parsedUrl);

    return response.data.data;
  };
}

/**
 * Use this function to construct a patch request function
 * @param axiosInstance Any Axios instance
 * @param url Any url. Url can contain route params, e.g. /house/:id/guests
 * @param axiosConfig Raw Axios Request Config
 * @returns async function
 */
export function patch<TPayload = unknown, TResponse = unknown>(
  axiosInstance: AxiosInstance,
  url: string,
  axiosConfig?: RawAxiosRequestConfig<TPayload>
) {
  /**
   * @param params Object
   * @param params.paramsMap An object that represents the route params to be replaced, e.g. {id: "moses"} (Optional)
   * @param params.body The body of the request (Optional)
   */
  return async ({
    paramsMap,
    body,
  }: {
    paramsMap?: ParamsMap;
    body?: TPayload;
  }): Promise<TResponse> => {
    const completeUrl = getCompleteUrl(url, paramsMap);

    const response = await axiosInstance.patch(completeUrl, body, axiosConfig);

    return response.data.data;
  };
}
