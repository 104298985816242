/* eslint-disable @typescript-eslint/no-explicit-any */
import type { StoreSlice } from '..';
import type { User } from '~/types/user.type';

export interface IUserSlice {
  currentUser: User | null;
  updateUser: (user: IUserSlice['currentUser']) => void;
  isTutor: boolean;
  isClient: boolean;
}

const userSlice: StoreSlice<IUserSlice> = (set) => ({
  isClient: false,
  isTutor: false,
  currentUser: null,
  updateUser: (user: IUserSlice['currentUser']) =>
    set({
      currentUser: user,
      isTutor: user?.role.toLowerCase() === 'tutor',
      isClient: user?.role.toLowerCase() === 'client',
    }),
});

export default userSlice;
