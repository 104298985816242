import { useMutation } from '@tanstack/react-query';
import { Id, toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { useRef } from 'react';

import ReactQueryKeys from '../keys';
import { apiV1 } from '../utils';

import type { AxiosError } from '../utils';

import useStore from '~/store';

import routes from '~/helpers/routes';

const logout = async () => {
  const response = await apiV1.get('/auth/signout');

  return response.data;
};

const useLogout = () => {
  const toastId = useRef<Id>('');

  const router = useRouter();

  const updateUser = useStore((state) => state.updateUser);

  return useMutation(logout, {
    mutationKey: [ReactQueryKeys.LOG_OUT],
    onMutate: () => {
      toastId.current = toast.info('Logging out...', { autoClose: false });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'You have been logged out successfully',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      updateUser(null);

      router.push(routes.login());
    },
    onError: (error: AxiosError) => {
      toast.update(toastId.current, {
        render: error.response?.data?.message,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
  });
};

export default useLogout;
