import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/nextjs';

import ReactQueryKeys from '../keys';
import { apiV1 } from '../utils';

import useStore from '~/store';

import routes from '~/helpers/routes';

import type { User } from '~/types/user.type';

const checkSession = async (): Promise<User> => {
  const response = await apiV1.get('/auth/check-session');

  return response.data.data;
};

const useCheckSession = () => {
  const router = useRouter();

  const currentUser = useStore((state) => state.currentUser);

  const updateUser = useStore((state) => state.updateUser);

  return useQuery([ReactQueryKeys.CHECK_SESSION], checkSession, {
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnMount: false,
    retry: false,
    retryOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !currentUser,
    onSuccess: (user) => {
      if (!user) {
        router.push(routes.login());

        return;
      }

      if (!['client', 'tutor'].includes(user.role?.toLowerCase())) {
        return;
      }

      Sentry.setUser({ email: user.primaryEmail, id: user.id });

      updateUser(user);
    },
  });
};

export default useCheckSession;
