import { FC, ReactNode, useEffect, useState } from 'react';
import Head from 'next/head';

import {
  DashboardMain,
  DashboardMainContainer,
  PageHeading,
  PageSubheading,
  DashboardPlainCard,
  useMediaQuery,
} from '@gate-academy/react';

import { capitalizeFirstLetter } from '@gate-academy/shared/utils';

import Header from '~/components/header/header.component';
import DesktopSidebar from '~/components/desktop-sidebar/desktop-sidebar.component';
import ProtectedLayout from '../protected-layout/protected-layout.component';
import AllowNotificationsCard from '~/components/allow-notifications-card/allow-notifications-card.component';

interface IGeneralLayoutProps {
  children: ReactNode;
  pageTitle: string;
}

type GeneralLayoutProps =
  | { noPageHeading: true; pageHeading?: never; pageSubheading?: never }
  | { noPageHeading?: false; pageHeading: string; pageSubheading: string };

const GeneralLayout: FC<IGeneralLayoutProps & GeneralLayoutProps> = ({
  children,
  pageTitle,
  pageHeading,
  pageSubheading,
  noPageHeading,
}) => {
  const [isComponentMounted, setIsComponentMounted] = useState(false);

  const isViewportGreaterThan1000px = useMediaQuery('(min-width: 62.5em)');

  const title = `GATE Academy | ${pageTitle}`;

  useEffect(() => {
    setIsComponentMounted(true);
  }, []);

  return (
    <ProtectedLayout>
      <Head>
        <title>{title}</title>
      </Head>

      <Header />

      <DashboardMainContainer>
        {isComponentMounted && isViewportGreaterThan1000px && (
          <DesktopSidebar />
        )}

        <DashboardMain>
          <AllowNotificationsCard />

          {!noPageHeading && (
            <DashboardPlainCard>
              <PageHeading>{pageHeading?.toLowerCase()}</PageHeading>

              <PageSubheading>
                {capitalizeFirstLetter(pageSubheading as string)}
              </PageSubheading>
            </DashboardPlainCard>
          )}

          {children}
        </DashboardMain>
      </DashboardMainContainer>
    </ProtectedLayout>
  );
};

export default GeneralLayout;
