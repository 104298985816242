import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { DATE_FORMAT } from '@gate-academy/shared/utils';

import {
  Button,
  DashboardPlainCard,
  PageHeading,
  PageSubheading,
  ShowView,
  SvgIcon,
} from '@gate-academy/react';

import { getItem, setItem } from '~/lib/localStorage';

import {
  triggerServiceWorkerUpdate,
  hasPermissionToSendPush,
  registerServiceWorker,
  requestPermissionToPush,
  subscribeToPush,
} from '~/utils/notification-utils';

const AllowNotificationsCard = () => {
  const [showPermissionCard, setShowPermissionCard] = useState(false);

  const hideCard = () => setShowPermissionCard(false);

  const enableNotifications = async () => {
    const isGranted = await requestPermissionToPush();

    if (isGranted) {
      await registerServiceWorker();

      await subscribeToPush();

      hideCard();
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!hasPermissionToSendPush()) {
      timeout = setTimeout(() => {
        setShowPermissionCard(true);
      }, 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  useEffect(() => {
    triggerServiceWorkerUpdate();
  }, []);

  useEffect(() => {
    const today = dayjs().format(DATE_FORMAT);

    const savedDay = getItem('today');

    if (savedDay !== today && hasPermissionToSendPush()) {
      subscribeToPush('update').then(() => {
        setItem('today', today);
      });
    }
  }, []);

  if (hasPermissionToSendPush()) {
    return null;
  }

  return (
    <>
      <ShowView when={showPermissionCard}>
        <DashboardPlainCard style={{ position: 'relative' }}>
          <PageHeading>Allow notifications?</PageHeading>

          <PageSubheading>
            Get notified of upcoming classes, updates to engagements, and more
          </PageSubheading>

          <Button
            blackBg
            style={{ marginTop: '1.6rem' }}
            onClick={enableNotifications}
          >
            Allow
          </Button>

          <SvgIcon
            iconName="close"
            style={{
              position: 'absolute',
              right: '1.6rem',
              top: '1.6rem',
              fill: 'rgba(var(--color-neutral5), 0.5)',
              cursor: 'pointer',
            }}
            onClick={() => {
              setShowPermissionCard(false);
            }}
          />
        </DashboardPlainCard>
      </ShowView>
    </>
  );
};

export default AllowNotificationsCard;
