// the 'today' key is used to decide if the app has resent the
// push subscription to the backend today
type LocalStorageKeys = 'today';

export const getItem = (key: LocalStorageKeys) => {
  return localStorage.getItem(key);
};

export const setItem = (key: LocalStorageKeys, value: string) => {
  localStorage.setItem(key, value);
};
