import styled from 'styled-components';

interface IHeaderWrapperStyleProps {
  isPageScrolled: boolean;
}

export interface NavItemPropTypes {
  isActive: boolean;
}

export const HeaderWrapper = styled.header<IHeaderWrapperStyleProps>`
  background-color: rgb(var(--color-white));
  padding: 3.6rem 4.8rem 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
  transition-property: padding, box-shadow;
  transition-duration: 0.2s;

  & > svg {
    cursor: pointer;
  }

  & img {
    cursor: pointer;
  }

  ${({ isPageScrolled }) =>
    isPageScrolled && 'box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05);'}

  @media only screen and (max-width: 20em) {
    padding: 2.4rem;
    padding-bottom: 1.2rem;
  }

  @media only screen and (min-width: 48.75em) {
    padding: 2.4rem 6.4rem;
  }
`;

export const UserInitials = styled.p`
  background-color: rgba(var(--color-primary), 0.1);
  padding: 7.5px 1.2rem;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 2rem;
  color: rgb(var(--color-neutral6));
  border-radius: 5px;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
`;
