import dayjs from 'dayjs';

import { DATE_FORMAT } from '@gate-academy/shared/utils';

import { apiV1 } from '~/react-query/utils';

import urlBase64ToUint8Array from './urlBase64ToUint8Array';

import { setItem } from '~/lib/localStorage';

const isNotificationSupported = () => {
  return typeof window !== 'undefined' && 'Notification' in window;
};

export const hasPermissionToSendPush = () => {
  if (isNotificationSupported()) {
    return Notification.permission === 'granted';
  }

  return false;
};

export const requestPermissionToPush = async () => {
  if (isNotificationSupported()) {
    const response = await Notification.requestPermission();

    return response === 'granted';
  }
};

export const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      await navigator.serviceWorker.register('/push-sw.js', {
        scope: '/',
      });
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
};

export const subscribeToPush = async (action: 'save' | 'update' = 'save') => {
  if ('PushManager' in window) {
    const registration = await navigator.serviceWorker.getRegistration();

    if (registration) {
      try {
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(
            process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY || ''
          ),
        });

        if (action === 'save') {
          await apiV1.post('/push-notifications/subscriptions', subscription);
        } else if (action === 'update') {
          await apiV1.patch('/push-notifications/subscriptions', subscription);
        }

        setItem('today', dayjs().format(DATE_FORMAT));
      } catch (err) {
        console.log(err);
      }
    }
  }
};

export const triggerServiceWorkerUpdate = async () => {
  if ('serviceWorker' in navigator) {
    const registration = await navigator.serviceWorker.getRegistration();

    if (registration) {
      // ensure the case when the updatefound event was missed is also handled
      // by re-invoking the prompt when there's a waiting Service Worker
      if (registration.waiting) {
        registration.waiting.postMessage('SKIP_WAITING');
      }

      registration.addEventListener('updatefound', () => {
        if (registration.installing) {
          // wait until the new Service worker is actually installed (ready to take over)
          registration.installing.addEventListener('statechange', () => {
            if (registration.waiting) {
              // if there's an existing controller (previous Service Worker), show the prompt
              if (navigator.serviceWorker.controller) {
                registration.waiting.postMessage('SKIP_WAITING');
              }

              // otherwise it's the first install, nothing to do
            }
          });
        }
      });
    }
  }
};
